import React from 'react';
import { AttributeTextValue, Customer, SalesOrder, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { ICultureInfoFormatter } from '@msdyn365-commerce/core-internal';
import { ICheckoutOrderConfirmationResources } from '../../definition-extensions/checkout-order-confirmation.ext.props.autogenerated';

export interface IOrderConfirmationDetailProps {
    cultureFormatter: ICultureInfoFormatter;
    salesOrder: SalesOrder;
    customer: Customer | undefined;
    deliveryMode: string;
    orderedProducts: SimpleProduct[];
    resources: ICheckoutOrderConfirmationResources;
    chargeLinesBreakdown: IChargeLinesBreakdownData[];
}

export interface IChargeLinesBreakdownData {
    chargeLineLabel?: string;
    chargeCode?: string;
}

export const otherChargesLines = (
    salesOrder: SalesOrder,
    chargeLinesBreakdown: IChargeLinesBreakdownData[],
    cultureFormatter: ICultureInfoFormatter
) => {
    const chargeCodes = chargeLinesBreakdown;
    let lines = salesOrder.ChargeLines;
    let linesNode: React.ReactNode[] = [];
    let otherChargeTotal = salesOrder.ChargeAmount || 0;
    let currencyFormatter = cultureFormatter.formatCurrency;

    chargeCodes?.map(c => {
        const node = lines
            ?.filter(line => line.ChargeCode === c.chargeCode)
            .map(r => {
                otherChargeTotal -= r.CalculatedAmount || 0;
                return renderLineChargeNode(c.chargeLineLabel || '', currencyFormatter(r.CalculatedAmount?.toString() ?? ''));
            });
        linesNode.push(node);
    });

    if (otherChargeTotal >= 0.01) {
        const otherChargesNode = renderLineChargeNode('Other Charges', currencyFormatter(otherChargeTotal.toString() ?? ''));
        linesNode.push(otherChargesNode);
    }

    return <>{linesNode}</>;
};

export const renderLineChargeNode = (chargeName: string, chargeAmount: string): React.ReactNode => {
    return (
        <p className='order-confirmation_other-charges'>
            <span className='order-information__copy--bold'>{chargeName}: </span>
            <span className='order-information__copy--regular'>{chargeAmount}</span>
        </p>
    );
};

export const OrderConfirmationDetail: React.FC<IOrderConfirmationDetailProps> = ({
    salesOrder,
    cultureFormatter,
    customer,
    deliveryMode,
    orderedProducts,
    resources,
    chargeLinesBreakdown
}) => {
    // const getProductDescription = (itemId: string): string => orderedProducts.find(p => p.ItemId === itemId)?.Description || '';
    const getProductName = (itemId: string): string => orderedProducts.find(p => p.ItemId === itemId)?.Name || '';
    const salesOrderAttrs = salesOrder.AttributeValues || [];
    const attr = salesOrderAttrs?.find(s => s.Name === 'po-number') as AttributeTextValue;

    return (
        <div>
            <div className={'order-confirmation'}>
                <h1 className={'order-confirmation__header'}>{resources.orderConfirmationHeading}</h1>
                <i className={'order-confirmation__print'} onClick={() => window.print()} />
            </div>

            <div className={'order-information'}>
                <div className='order-information__wrapper'>
                    <div className={'order-information__heading'}>{resources.orderInformationHeading}</div>
                    <div className='order-information__details'>
                        <div className={'order-information__po-num'}>
                            <p className={'order-information__copy--bold'}>{resources.webReferenceNumber}</p>
                            <p className={'order-information__copy--regular'}>{salesOrder.ChannelReferenceId}</p>
                        </div>
                        <div className={'order-information__po-num'}>
                            <p className={'order-information__copy--bold'}>{resources.salesOrderNumber}</p>
                            <p className={'order-information__copy--regular'}>{resources.salesOrderPending}</p>
                        </div>
                        {attr ? (
                            <div className={'order-information__po-num'}>
                                <p className={'order-information__copy--bold'}>{resources.poNumber}</p>
                                <p className={'order-information__copy--regular'}>{attr.TextValue}</p>
                            </div>
                        ) : (
                            undefined
                        )}

                        <div className='order-information__date'>
                            <p className={'order-information__copy--bold'}>{resources.orderDate}</p>
                            <p className={'order-information__copy--regular'}>
                                {cultureFormatter.formatDate(salesOrder.CreatedDateTime!, {
                                    year: 'numeric',
                                    month: 'short',
                                    day: '2-digit'
                                })}
                            </p>
                        </div>
                    </div>
                    {salesOrder.Comment ? (
                        <div className='order-information__details'>
                            <div className={'order-information__comment'}>
                                <p className={'order-information__copy--bold'}>{resources.notes}</p>
                                <p className={'order-information__copy--regular'}>{salesOrder.Comment}</p>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className='order-information__row'>
                    <div className='order-information__wrapper order-information__wrapper--two-third'>
                        <div className={'order-information__heading'}>{resources.accountInformationHeading}</div>
                        <div className='order-information__details'>
                            <div className={'order-information__acc-name'}>
                                <p className={'order-information__copy--bold'}>{resources.accountName}</p>
                                <p className={'order-information__copy--regular'}>{customer?.FirstName}</p>
                            </div>
                            <div className='order-information__date'>
                                <p className={'order-information__copy--bold'}>{resources.accountNumber}</p>
                                <p className={'order-information__copy--regular'}>{customer?.AccountNumber}</p>
                            </div>
                        </div>
                    </div>
                    <div className='order-information__wrapper order-information__wrapper--one-third'>
                        <div className={'order-information__heading'}>{resources.shippingInformationHeading}</div>
                        <div className='order-information__details order-information__details--col'>
                            <div className={'order-information__ship-address'}>
                                <p className={'order-information__copy--bold'}>{resources.shippingAddress}</p>
                                <p className={'order-information__copy--regular'}>{salesOrder.ShippingAddress?.Name}</p>
                                <p className={'order-information__copy--regular'}>{salesOrder.ShippingAddress?.FullAddress}</p>
                            </div>
                            <div className={'order-information__dlv-mode'}>
                                <p className={'order-information__copy--bold'}>{resources.shippingMethod}</p>
                                <p className={'order-information__copy--regular'}>{deliveryMode}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <table className={'table'}>
                <thead className={'order-confirmation-header'}>
                    <tr>
                        <th>{resources.line}</th>
                        <th>{resources.itemId}</th>
                        <th>{resources.description}</th>
                        <th>{resources.price}</th>
                        <th>{resources.quantity}</th>
                        <th>{resources.total}</th>
                    </tr>
                </thead>
                <tbody>
                    {salesOrder.SalesLines?.map((so, idx) => (
                        <tr>
                            <td>{so.LineNumber}</td>
                            <td>{so.ItemId}</td>
                            <td>
                                {getProductName(so.ItemId!)}
                                {so.Comment ? (
                                    <>
                                        <span className={'order-information__copy--bold'}>Notes: </span>
                                        <span className={'order-information__copy--regular'}>{so.Comment}</span>
                                    </>
                                ) : null}
                            </td>
                            <td>{so.OriginalPrice ? cultureFormatter.formatCurrency(so.OriginalPrice) : 'N/A'}</td>
                            <td>{so.Quantity}</td>
                            <td>{so.TotalAmount ? cultureFormatter.formatCurrency(so.TotalAmount) : 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className={'order-confirmation__footer'}>
                {salesOrder.ChargeLines && otherChargesLines(salesOrder, chargeLinesBreakdown, cultureFormatter)}

                <div className={'order-confirmation_order-total'}>
                    <p className={'order-information__copy--bold'}>{resources.orderTotalAmount}</p>
                    <p className={'order-information__copy--regular'}> {salesOrder.TotalAmount ? cultureFormatter.formatCurrency(salesOrder.TotalAmount) : 'N/A'}</p>
                </div>
            </div>
        </div>
    );
};
